<template>
  <div class="star-rating">
      <div v-for="n in max" :key="n">
          <img :src="starCalc(n)" alt="">
      </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps(['max', 'current']);
const getRating = computed(() => props.current / props.max * 100);
const starCalc = (n) => {
  if (n <= props.current) return '/img/full-star.svg';
  if (n > props.current && props.current % 1 !== 0 && n - props.current < 1.5 && n - props.current < 1) return '/img/half-star.svg';
  return '/img/star.svg'
};

</script>

<style scoped>
@media (max-width: 480px) {
  .star-rating {
  justify-content: flex-end;
  width: 46%;
}
}
.star-rating {
  display: flex;

}
</style>