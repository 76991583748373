<template>
  <div class="showcase-wrapper v4">
    <div class="card" v-for="offer in offers">
      <div class="card-wrapper">
        <div class="card-logo">
          <img :src="offer.logo" alt="" />
        </div>
        <div class="card-info">
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__text">Сумма</div>

            <div class="card-info__wrapper__value">до {{
              numberFormat(offer.amount)
            }} р.</div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__text">Без %</div>

            <div class="card-info__wrapper__value">{{ offer.without_percent ? 'да' : 'нет' }}</div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__text">Срок</div>

            <div class="card-info__wrapper__value">до {{
              offer.period
            }} дней</div>
          </div>
        </div>
        <div class="card-btn">
          <button @click="openLinks(offer)">
            <img src="/img/btn-card.svg" alt="">
            <span>Получить заем</span>
          </button>
        </div>
        <div class="card-info__rate">
          <div class="card-info__rate__title">Народный рейтинг</div>
          <div class="card-info__rate__value">{{ offer.rating }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";
import StarRating from "@/components/StarRating";
export default {
  name: "ShowcaseV4",
  components: {
    StarRating,
  },
  props: {
    offers: Array,
  },

  data: () => ({
    ratingText: Data.getRatingText(),
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    openLinks(offer) {
      window.open(offer.link, "_blank");
      offer.links.forEach((link) => {
        window.open(link, "_blank");
      });
    },
  },
};
</script>
<style lang="css">
@import url(../assets/css/styleShowcaseV4.scss);
@import url(../assets/css/styleFiltersV4.scss);
</style>
