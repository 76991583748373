/*
 * Модуль для получения значений из url параметров
 */

const getParams = (new URL(document.location)).searchParams;

/*
 * Получение get параметра из адресной строки
 */
var get = function(key) {
	return getParams.get(key);
};

export { get }
