<template>
  <div class="showcase-wrapper v6">
    
    <div class="card">
      <div class="card-wrapper">
        <div class="card-logo">
          <img src="/img/logo.png" alt="" />
        </div>
        <div class="card-info">
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__text">Сумма</div>
            <div class="card-info__wrapper__symbol-top">
              <img src="/img/arrow.svg" alt="">
            </div>

            <div class="card-info__wrapper__value">до 100000 р.</div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__text">Без %</div>
            <div class="card-info__wrapper__symbol-mid">
              <img src="/img/arrow.svg" alt="">
            </div>
            <div class="card-info__wrapper__value">да</div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__text">Срок</div>
            <div class="card-info__wrapper__symbol-low">
              <img src="/img/arrow.svg" alt="">
            </div>
            <div class="card-info__wrapper__value">до 365 дней</div>
          </div>
          <div class="card-btn">
          <button @click="openLinks(offer)">
            <img src="/img/btn-card-1.svg" alt="">
            <span>Получить заем</span>
          </button>
        </div>
        </div>
       
        <div class="card-info__rate">
          <div class="card-info__rate__title">Народный рейтинг</div>
          <div class="card-info__rate__separator">|</div>
          <div class="card-info__rate__value">4.5</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";
import StarRating from "@/components/StarRating";
export default {
  name: "ShowcaseV6",
  components: {
    StarRating,
  },
  props: {
    offers: Array,
  },

  data: () => ({
    ratingText: Data.getRatingText(),
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    openLinks(offer) {
      window.open(offer.link, "_blank");
      offer.links.forEach((link) => {
        window.open(link, "_blank");
      });
    },
  },
};
</script>
<style lang="css">
@import url(../assets/css/styleShowcaseV6.scss);
</style>
