<template>
  <div class="" v-for="offer in offers">
      <button class="partner__btn btn">
        <a
          class="btn-send-full btn-primary"
          @click="openLinks(offer)"
          role="button"
        >
          <div class="txt txt-white fw-smb">{{ buttonText }}</div>
        </a>
      </button>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";

export default {
  name: "ShowcaseOneBtn",

  props: {
    offers: Array,
  },

  data: () => ({
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    
    openLinks(offer) {
    window.open(offer.link, "_blank");
    offer.links.forEach((link) => {
      window.open(link, "_blank");
    });
  },
  },

  
};
</script>
