/*
 * Модуль для получения значений data параметров
 */

const script = document.currentScript || document.querySelector('script[src*="showcase.js"]');

/*
 * Получение data- параметра из подключеного скрипта
 */
var get = function(key) {
	return script.dataset[key] || null;
};
var testGet = function() {
	return script.dataset;
}
/*
 * Получить текст описания рейтинга
 */
var getRatingText = function() {
	return get('ratingText') || 'Народный рейтинг';
};

/*
 * Получить текст кнопок
 */
var getButtonText = function() {
	return get('buttonText') || 'Подать заявку';
};

export { getRatingText, getButtonText, get, testGet }
