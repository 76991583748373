import Showcase from './Showcase.vue'
import ShowcaseOneBtn from './ShowcaseOneBtn.vue'
import ShowcaseV2 from './ShowcaseV2.vue'
import ShowcaseV3 from './ShowcaseV3.vue'
import ShowcaseV4 from './ShowcaseV4.vue'
import ShowcaseV5 from './ShowcaseV5.vue'
import ShowcaseV6 from './ShowcaseV6.vue'
import ShowcaseFilters from './ShowcaseFilters.vue'
import ShowcaseFiltersV3 from './ShowcaseFiltersV3.vue'
import ShowcaseFiltersV4 from './ShowcaseFiltersV4.vue'
import ShowcaseFiltersV5 from './ShowcaseFiltersV5.vue'
import ShowcaseFiltersV6 from './ShowcaseFiltersV6.vue'

export default {
    'v-showcase-default': Showcase,
    'v-showcase-OneBtn': ShowcaseOneBtn,
    'v-showcase-v2': ShowcaseV2,
    'v-showcase-v3': ShowcaseV3,
    'v-showcase-v4': ShowcaseV4,
    'v-showcase-v5': ShowcaseV5,
    'v-showcase-v6': ShowcaseV6,


    //filters
    'v-showcase-filter-default': ShowcaseFilters,
    'v-showcase-filter-v3': ShowcaseFiltersV3,
    'v-showcase-filter-v4': ShowcaseFiltersV4,
    'v-showcase-filter-v5': ShowcaseFiltersV5,
    'v-showcase-filter-v6': ShowcaseFiltersV6,
}