<template>
  <div class="showcase-wrapper v3">
    <div class="card" v-for="offer in offers">
      <div class="card-wrapper">
        <div class="card-logo">
          <img :src="offer.logo" alt="" />
        </div>
        <div class="card-info">
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/summa.svg" alt="" />
            </div>
            <div class="card-info__wrapper__text">Сумма</div>
            <div class="seperator">|</div>
            <div class="card-info__wrapper__value">до {{
              numberFormat(offer.amount)
            }} р.</div>
          </div>

          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/period.svg" alt="" />
            </div>
            <div class="card-info__wrapper__text">Срок</div>
            <div class="seperator-center">|</div>
            <div class="card-info__wrapper__value">до {{
              offer.period
            }} дней</div>
          </div>

          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/percent.svg" alt="" />
            </div>
            <div class="card-info__wrapper__text">Без %</div>
            <div class="seperator-bottom">|</div>
            <div class="card-info__wrapper__value">{{ offer.without_percent ? 'да' : 'нет' }}</div>
          </div>
        </div>
        <div class="card-btn">
          <button @click="openLinks(offer)">Получить заем</button>
        </div>
        <div class="card-info__rate">
          <div class="card-info__rate__title">Народный рейтинг</div>
          <div class="card-info__rate__value">
            <star-rating :max="5" :current="offer.rating"></star-rating>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";
import StarRating from "@/components/StarRating";
export default {
  name: "ShowcaseV3",
  components: {
    StarRating,
  },
  props: {
    offers: Array,
  },

  data: () => ({
    ratingText: Data.getRatingText(),
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    openLinks(offer) {
      window.open(offer.link, "_blank");
      offer.links.forEach((link) => {
        window.open(link, "_blank");
      });
    },
  },
};
</script>
<style lang="css">
@import url(../assets/css/styleShowcaseV3.scss);

</style>
