<template>
  <div class="filters-container v6">
    <div class="filters-title">Фильтр предложений</div>
    <div class="filters">
      <div class="filters-wrapper">
        <div class="first-filter__wrapper">
          <div class="filter">
            <label>Сумма займа руб. до</label>
            <input
              class="sum-input"
              :value="modelValue.amount"
              @input="change('amount', $event.target.value)"
            />
          </div>
          <div class="filter">
            <label>Срок дн. до</label>
            <input
              class="days-input"
              :value="modelValue.period"
              @input="change('period', $event.target.value)"
            />
          </div>
          <div class="filter">
            <label>Рейтинг</label>
            <select
              :value="modelValue.rating"
              @change="change('rating', $event.target.value)"
              class="rating-input"
            >
              <template :key="rating" v-for="rating in [1, 2, 3, 4, 5]">
                <option :value="rating">от {{ rating }}</option>
              </template>
            </select>
          </div>
        </div>

        <div class="last-filter__wrapper">
          <div class="filter">
            <label>Без %</label>
            <input
              type="checkbox"
              :checked="modelValue.without_percent"
              @change="change('without_percent', $event.target.checked)"
            />
          </div>
          <div class="filter" @click="clear">
            <div class="close-icon">
              <img src="/img/close_icon.svg" alt="" />
            </div>

            <a class="clear-btn">сбросить</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import customSelect from "../assets/js/custom_select.js";
export default {
  name: "ShowcaseFiltersV6",

  props: {
    modelValue: Object,
  },

  data: () => ({}),

  methods: {
    change(key, value) {
      this.$emit("update:model-value", { ...this.modelValue, [key]: value });
    },
    clear() {
      this.$emit("update:model-value", {
        ...this.modelValue,
        amount: null,
        period: null,
        rating: null,
        without_percent: null,
      });
    },
  },
  mounted() {
    customSelect.event.manage();
  },
};
</script>
<style lang="css">
@import url(../assets/css/styleFiltersV6.scss);



</style>
