
import * as getParams from '../../assets/js/get_params.js'

var getUrl = function () {
	let host = window.location.hostname;
	let params = '';
	if (getParams.get('utm_source')){
		let utm_source = getParams.get('utm_source');
		params += '&utm_source=' + utm_source;
	}
	if (getParams.get('credit_history')){
		let credit_history = getParams.get('credit_history');
		params += '&credit_history=' + credit_history;
	}
	if (getParams.get('bid_id')){
		let bid_id = getParams.get('bid_id');
		params += '&bid_id=' + bid_id;
	}
	return process.env.VUE_APP_SHOWCASE_API + '?category=мфо&host=' + host + params;
};

var load = function () {
	return fetch(getUrl(), {
		method: 'GET',
		headers: {'Content-Type': 'application/json'}
	}).then(response => {
		return response.json();
	}).then(response => {
		return response;
	});
};

export { load }
