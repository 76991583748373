<template>
  <div class="partner" v-for="offer in offers">
    <div
      class="partner__top partner__top-red txt-body"
      v-if="offer.title !== ''"
    >
      {{ offer.title }}
    </div>
    <div class="partner__header">
      <div class="partner__logo">
        <img :src="offer.logo" :alt="offer.name" />
      </div>
    </div>
    <div class="partner-information">
      <div class="partner-data">
        <div class="partner__summ">
          <img
            class="partner__summ-static partner__static"
            src="/img/summa.png"
            alt=""
          />
          <div class="partner__summ-value">
            <span>до </span
            ><span class="value-r" id="partnerSummMax">{{
              numberFormat(offer.amount)
            }}</span
            ><span> ₽</span>
          </div>
        </div>
        <div class="partner__rate">
          <img
            class="partner__rate-static partner__static"
            src="/img/stavka.png"
            alt=""
          />
          <div class="partner__rate-value">
            <span>под </span
            ><span class="value-r" id="partnerRateValue"
              >{{ offer.percent }}%</span
            >
          </div>
        </div>
        <div class="partner__date">
          <img
            class="partner__date-static partner__static"
            src="/img/srok.png"
            alt=""
          />
          <div class="partner__date-value">
            <span>до </span
            ><span class="value-r" id="partnerDateMaxValue">{{
              offer.period
            }}</span
            ><span> дн.</span>
          </div>
        </div>
      </div>
      <button class="partner__btn btn">
        <a
          class="btn-send-full btn-primary"
          @click="openLinks(offer)"
          role="button"
        >
          <div class="txt txt-white fw-smb">{{ buttonText }}</div>
        </a>
      </button>
    </div>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";

export default {
  name: "ShowcaseV2",

  props: {
    offers: Array,
  },

  data: () => ({
    ratingText: Data.getRatingText(),
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    openLinks(offer) {
    window.open(offer.link, "_blank");
    offer.links.forEach((link) => {
      window.open(link, "_blank");
    });
  },
  },

  
};
</script>
