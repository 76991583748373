<style>
@import './assets/css/style.css';
</style>

<template>
    <div id="showcase-app-loadser">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div>
        <component :is="filterName" v-model="filterForm" />
    </div>
    <div class="row">
        <component :is="componentName" :offers="offersFiltered" />
    </div>
</template>

<script>
import * as ShowcaseLoader from './assets/js/showcase.js'
import Showcases from './components';
import * as Data from './assets/js/data_params.js'

var formType = Data.get('type') || 'default';
var filterType = Data.get('filterType') || 'default';


export default {
    name: 'App',

    components: Showcases,

    data: () => ({
        offers: [],
        filterForm: {
            amount: null,
            period: null,
            rating: null,
            without_percent: null,
        },
        formType: formType,
        filterType: filterType,
    }),

    computed: {
        componentName() {
            return `v-showcase-${this.formType}`
        },
        filterName() {
            return `v-showcase-filter-${this.filterType}`
        },
        offersFiltered() {
    return this.offers.filter(offer => {
        return Object.keys(this.filterForm).every(key => {
            if (!this.filterForm[key]) return true;
            switch(key) {
                case 'period':
                case 'amount': return this.filterForm[key] <= offer[key];
                case 'without_percent': return (this.filterForm[key] && offer[key]) || !this.filterForm[key];
                case 'rating': return offer[key] >= this.filterForm[key];
                default: return this.filterForm[key] === offer[key];
            }
        });
    });
}
    },

    mounted() {
        ShowcaseLoader.load().then(showcase => {
            document.getElementById('showcase-app-loadser').remove();

            var $name = document.getElementById('showcase-app-name');
            var $rating = document.getElementById('showcase-app-rating');

            if (!!$name) {
                $name.innerHTML = showcase.name;
            }

            if (!!$rating) {
                $rating.innerHTML = showcase.creditRating;
            }

            this.offers = showcase.offers;
        });
    }
}
</script>
